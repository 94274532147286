/* #### Generated By: http://www.cufonfonts.com #### */

    @font-face {
    font-family: 'Peak Sans Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Peak Sans Regular'), url('./PeakSans_Regular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Peak Sans Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Peak Sans Bold'), url('./PeakSans_Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Peak Sans Black';
    font-style: normal;
    font-weight: normal;
    src: local('Peak Sans Black'), url('./PeakSans_Black.woff') format('woff');
    }