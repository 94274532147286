@import-normalize;

html {
  width:100vw;
  height: fill-available;
  height: -webkit-fill-available;
  overflow-x:hidden;
}

body {
  width:100vw;
  min-height: 100vh;
    min-height: fill-available;
    min-height: -webkit-fill-available;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000A3C !important;
    
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button{
  background-color: transparent;
  border-width: 0;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  padding: 0;
}


#root{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}