.menuLink{
    display: flex;
    flex-flow: column wrap;
    transition:all .2s ease-out;
    border-bottom: 0px solid;
    font-family: "Peak Sans Black" !important;

    &:after{
        content:"";
        width: 0%;
        border-bottom: 2px solid;
        transition:all .2s ease-out;
    }

    &:hover{
        filter: brightness(3);
        &:after{
            content:"";
            width: 100%;
        }
   }
}