.MuiPagination-ul{
    li{
        button{
            color: #ffffff !important;
            border: 1px solid #ffffff;
           &.Mui-selected{
            border: 0px;
            background-color: #000A3C;
            filter: brightness(3);
           }
           &.MuiPaginationItem-outlined{
            &:hover{
                border: 0px;
                background-color: #000A3C;
                filter: brightness(3);
            }
           }
        }
        div{
            color: #ffffff !important;

        }
    }
}